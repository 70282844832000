.csv-guide-container {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  mark {
    background-color: #f0f0f0;
  }

  .sub-header {
    font-size: 18px;
  }

  .usa-card__header {
    padding-bottom: 0.5em;
  }

  .bullet-list {
    list-style-type: disc;
    list-style-position: inside;
  }

  .normal-style {
    font-style: normal;
  }
}

.csv-section-tablist {
  li:first-child {
    padding-left: 0;
  }

  .tab-button {
    display: inline-block;
  }

  .tab-selected,
  .tab-button:hover {
    border-bottom: 0.25rem solid #005ea2;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }
}

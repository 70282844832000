@use "../../../scss/settings" as settings;

.list-style-none {
  list-style: none;
}

.card-close-button {
  color: settings.$theme-color-prime-gray-darkest !important;
}

.test-card-header-bottom-border {
  border-bottom: 2px solid #dfe1e2;
}

.test-card-container {
  max-width: 64rem;
  transition: opacity 2s;
}

.usa-card__container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.test-card-list {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

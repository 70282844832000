.user-name-header {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  color: #1b1b1b;
}

.status-tagline {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #3d4551;
}

.user-status-subheader {
  svg {
    transform: scale(1.27);
    margin-left: 4px;
  }
}

@use "../../scss/settings";

@media print {
  .usa-banner {
    display: none;
  }
}

.usa-banner {
  .usa-banner__inner {
    max-width: settings.$site-max-width;
  }
}

.pxp-test-results {
  #section-to-print,
  #section-to-print * {
    visibility: hidden;
  }

  #section-to-print {
    display: none;
  }

  a[href^='http:']:not([href*='simplereport.gov']),
  a[href^='https:']:not([href*='simplereport.gov']) {
    &::after {
      bottom: 0.25rem;
      height: 1rem;
    }
  }

  @media print {
    body * {
      visibility: hidden;
    }

    .patient-app {
      display: none;
    }

    #section-to-print,
    #section-to-print * {
      visibility: visible;
    }

    .print-area {
      font-size: 80% !important;
      background: #fff;
      overflow: auto;
      transform: none;
      max-height: none;
    }

    #section-to-print {
      display: unset;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

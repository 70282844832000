@use "../../../scss/settings";

.sr-test-results-list {
  .sticky-heading {
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 10;
    box-shadow: 0 4px 12px rgb(0 0 0 / 20%);

    .usa-table {
      margin-bottom: 0;
    }
  }

  .sr-more-test-results {
    display: flex;
    justify-content: center;
    margin: 1em 0;
  }

  .sr-test-result-row th,
  .sr-test-result-row td {
    /* override USWDS force-white */
    background: inherit;
  }

  .sr-test-result-row--removed {
    text-decoration: line-through;
  }

  .grid-row.grid-gap {
    .usa-form-group {
      padding-right: 0;
      margin-top: 0;
    }
  }

  .usa-date-picker__external-input {
    max-width: 9rem;
  }

  .usa-date-picker__button {
    max-width: 2.5em;
  }

  .usa-date-picker__calendar {
    min-width: 16rem;
  }

  .usa-error-message {
    max-width: 11rem;
  }

  .person-search {
    .prime-search-container {
      margin-bottom: 0;
      min-width: 10rem;
      max-width: 13rem;

      .prime-search-input {
        margin-bottom: 0;

        .usa-input {
          margin-top: 0.5rem;
          margin-bottom: 0;
          height: 2.5rem;
        }

        .usa-button {
          margin-top: 0.5rem;
          height: 2.5rem;
        }
      }
    }

    .results-dropdown {
      top: 5.5rem;
    }
  }

  thead th,
  .sr-test-result-row td {
    padding-left: 12px;
    padding-right: 12px;
    word-wrap: anywhere;

    &.patient-name-cell {
      padding-left: 1.5rem;
      padding-right: 12px;
      width: 15%;
    }

    &.test-date-cell {
      width: 9%;
    }

    &.test-condition-cell {
      width: 9%;
    }

    &.test-result-cell {
      width: 9%;
    }

    &.test-facility-cell {
      width: 16%;
    }

    &.test-device-cell {
      width: 16%;
    }

    &.submitted-by-cell {
      width: 10%;
    }

    &.actions-cell {
      padding-right: 1.5rem;
      word-wrap: break-word;
      width: 7%;
    }
  }

  div[title="filtered-result"] .usa-table {
    margin-top: 0;
  }

  .usa-table td {
    border-color: #a9aeb1;
  }
}

.sr-showing-results-on-page {
  color: #555;
  font-size: 0.69rem;
}

.search-input_without_submit_button {
  input {
    border: 1px solid rgb(86, 92, 101);
  }
}

.grid-container.results-wide-container {
  max-width: settings.$site-max-width;
}

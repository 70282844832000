@use "../../scss/settings";

.grid-container.queue-container-wide {
  max-width: settings.$site-max-width;
}

.prime-test-result {
  .usa-radio {
    white-space: nowrap;
  }
}

@use "../settings" as *;
@use "../components" as *;
@use "@uswds/uswds/packages/usa-prose";
@use "@uswds/uswds/packages/usa-link";

.prime-home {
  background-color: color("base-lightest");
  padding-top: units(2);
  padding-bottom: units(2);

  .grid-container {
    max-width: 1200px;
  }
}

.prime-container {
  width: 100%;
  margin-bottom: 1.5rem;

  .usa-card__header {
    padding: units(3);
    border-bottom: 1px solid color("base-lighter");
    display: flex;
    justify-content: space-between;

    h2 {
      margin: 0;
    }
  }

  .usa-card__header > :last-child,
  .usa-card__body > :last-child {
    &.usa-button {
      padding: 12px 20px;
      margin-right: 0;
    }
  }

  .usa-card__body {
    .usa-form-group {
      margin-left: 0;
      padding: 0;
    }

    .usa-form-group--error {
      padding-left: units(1);
    }
  }
}

.prime-ul {
  list-style-type: none;
  padding: 0;

  p {
    margin-bottom: 0;
    margin-top: units(1);
  }
}

.prime-right-align {
  float: right;
}

.prime-center {
  text-align: center;
}

.prime-search-input {
  margin-bottom: units(1);
  width: 100%;

  @include at-media("mobile-lg") {
    max-width: 22em;
  }
}

.prime-search-container {
  margin-bottom: units(1);
}

// patient in the queue

.prime-test {
  &-result {
    padding: 0 1em;

    @include at-media("desktop") {
      border-left: 1px solid $theme-color-prime-gray-light;
    }

    @include at-media("tablet") {
      border-left: 1px solid $theme-color-prime-gray-light;
    }

    &-submit {
      margin-top: 1em;
      margin-bottom: 1em;

      @include at-media("desktop") {
        margin-top: 0;
      }

      .usa-button {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }

  &-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid $theme-color-prime-gray-light;

    > button {
      margin-top: 0.5rem;
    }

    @include at-media("desktop") {
      flex-direction: row;

      > button {
        margin-top: 0;
      }
    }

    @include at-media("tablet") {
      flex-direction: row;

      > button {
        margin-top: 0;
      }
    }
  }

  &-body {
    padding-left: 1rem;

    .prime-li {
      > button {
        font-weight: 700;
      }
    }

    .usa-form-group {
      margin-top: 0;

      .usa-label,
      .usa-legend {
        font-weight: 700;
      }
    }

    .checkboxes {
      @include at-media("desktop") {
        margin-top: 1rem;
      }

      @include at-media("tablet") {
        margin-top: 1rem;
      }
    }

    .usa-select {
      min-width: 197px;
    }

    .test-information-ul {
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @include at-media("desktop") {
        flex-direction: row;
        width: unset;
      }

      @include at-media("tablet") {
        flex-direction: row;
        width: unset;
      }

      .prime-li {
        width: 100%;

        @include at-media("desktop") {
          width: unset;
        }

        @include at-media("tablet") {
          width: unset;
        }
      }
    }
  }
}

.queue-item__description {
  .prime-li:not(:first-child) {
    margin-top: units(2);

    @include at-media("tablet") {
      margin-top: 0;
    }
  }
}

.queue-item__form--open {
  @include at-media("tablet") {
    align-items: flex-end;
  }
}

.testdate-checkbox {
  .checkboxes {
    @include at-media("desktop") {
      margin-top: 2.5rem;
    }
  }
}

.prime-queue-item {
  padding: 0;
  position: relative;

  .prime-card-container {
    border-left-width: 0.5rem;
    border-left-style: solid;
    border-color: transparent;
  }

  .prime-test-name {
    margin-left: -0.5rem;
  }

  .result-ready {
    font-weight: bold;
  }

  .usa-card__footer .usa-form label {
    margin-top: 0.5rem;
  }

  &__error,
  &__ready,
  &__info {
    .prime-card-container .usa-card__header {
      margin-left: 0;
      padding-left: 0.75rem;
    }
  }

  &__error {
    border-left-width: 0.25rem;
    border-color: color($theme-color-error);

    .prime-card-container {
      border-left-width: 0.25rem;
      border-color: color($theme-color-error);
    }
  }

  &__ready {
    border-color: color($theme-color-warning);

    .prime-card-container {
      border-color: color($theme-color-warning);
    }

    .timer-ready {
      background-color: color($theme-color-warning);
      color: $theme-color-prime-gray-darkest;
      padding: units(1);

      .timer-overtime {
        color: $theme-color-prime-gray-darkest;
        margin: 0 units(1);
      }
    }
  }

  &__info {
    border-color: $theme-color-prime-blue;

    .prime-card-container {
      border-color: $theme-color-prime-blue;
    }
  }

  &__completed {
    .timer-ready {
      background-color: color($theme-color-warning-lighter);
      color: $theme-color-prime-gray-darkest;
      padding: units(1);

      .timer-overtime {
        color: $theme-color-prime-gray-darkest;
        margin: 0 units(1);
      }
    }
  }
}

.prime-card-container {
  .usa-card__header {
    padding: units(2.5);
  }
}

.prime-container-padding {
  padding: 1rem;
}

.prime-container-padding-2x {
  padding: 2rem;
}

.prime-previous-test-display {
  border-left: units(0.5) solid color("base-lighter");
  padding-left: units(1.5);
}

// close button

.prime-close-button {
  top: 1.5rem;
  right: 1.5rem;
  display: block;
  position: absolute;
  z-index: 10;
  border: none;
  background: transparent;
  cursor: pointer;

  @include at-media("desktop") {
    top: -0.5rem;
    right: -0.25rem;
  }

  @include at-media("tablet") {
    top: -0.5rem;
    right: -0.25rem;
  }
}

// other buttons
.sr-active-button {
  background-color: color($theme-color-primary-darker);
  color: #fff;
}

.prime-red-icon {
  color: color($theme-color-secondary);
}

.prime-orange-icon {
  color: orange;
}

.prime-blue-icon {
  color: color($theme-color-primary);
}

// General group of related items on a form
.sr-section {
  border: 1px solid #ccc;
  padding: 1em;

  > h3 {
    font-size: 2rem;
    color: $theme-color-primary;
  }
}

$results-dropdown-spacing: #{units(4)} - #{units(2)} - 22px - #{units(4)}; // height of search bar, padding, gov banner, bottom spacing

//test queue search
.results-dropdown {
  height: auto;
  max-height:
    calc(
      100vh - #{units(6)} - #{$results-dropdown-spacing}
    ); // uses header height on small screens// uses header height on small screens
  overflow-y: auto;
  position: absolute;
  top: units(4);
  z-index: z-index(500);
  width: 100%;

  @include at-media("desktop") {
    max-height: calc(100vh - 70px - #{$results-dropdown-spacing});
    width: unset;
  }

  &__body {
    padding: 0.5rem;

    &:only-child {
      padding: 0.5rem;
    }

    > p {
      margin: 1.5rem 1rem;
    }

    @include at-media("desktop") {
      padding: 1rem;

      &:only-child {
        padding: 1rem;
      }
    }

    .usa-table {
      width: 100%;
      margin-bottom: 1rem;

      th,
      td {
        padding: 0.5rem;

        &:first-child {
          padding: 0.5rem;
          max-width: 30vw;
          text-overflow: ellipsis;
          overflow-wrap: break-word;
        }

        @include at-media("desktop") {
          padding: 0.5rem 1rem;

          :first-child {
            padding: 0.5rem 1rem;
          }
        }
      }

      .usa-button {
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

// radio buttons

.prime-radio {
  &__container {
    padding: 0.6em;
  }

  &__title {
    margin-bottom: 0;
    margin-top: 1.33rem;
    font-size: 1rem;
  }

  &__label {
    margin-top: auto;
  }

  &__group {
    margin-top: 0.5rem !important;

    .usa-radio {
      margin-bottom: 1rem;
    }
  }

  &--success {
    background-color: $theme-color-prime-green-lightest;
  }

  &--failure {
    background-color: $theme-color-prime-red-lightest;
  }

  &--horizontal {
    list-style-type: none;
    margin-top: 0;
    padding: 0;

    &__container {
      position: relative;
      display: list-item;
      max-width: 5rem;
      float: left;
      margin: 0;

      &:first-child {
        margin-right: units(1);
      }
    }
  }
}

// MODAL
// the background behind the modal
.prime-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.modal--basic {
  .ReactModal__Content {
    max-height: 90vh;
    width: 700px;

    @media (max-width: 48rem) {
      width: units("mobile-lg");
    }
  }

  .modal__container {
    position: relative;
  }

  .modal__close-button {
    position: absolute;
    right: 0;
  }

  .modal__heading {
    margin-right: units(3);
  }

  .modal__variant {
    h3 {
      margin: 0.3125rem 0 0 0;
      font-size: 1.375rem;
      line-height: 1.62rem;
    }

    .modal__content {
      padding: 2rem 1.875rem;

      p {
        margin: 0.75rem 0 0 0;
      }
    }

    .modal__footer {
      display: flex;
      justify-content: flex-start;
      margin: 1.5rem 0 0 0;
    }
  }

  .modal__footer {
    display: flex;
    justify-content: flex-end;

    > *:not(:first-child) {
      margin-left: units(2);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal__close-button {
  @include button-unstyled;
  @include u-square($size-touch-target);

  display: block;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: 0;
  text-align: center;
}

.modal__close-img {
  width: units(4);
}

.usa-alert--success {
  outline: 3px solid #00a91c;
}

.prime-secondary-nav {
  margin-top: units(1);
}

.prime-secondary-nav.results {
  background-color: color("base-lightest");
  padding-top: units(2);
}

.prime-nav.usa-nav__secondary-links {
  display: flex;
  float: none;
  margin-bottom: 0;
  position: relative;

  .usa-nav__secondary-item:not(:first-child) {
    padding-left: units(2.5);

    &::before {
      display: none;
    }
  }

  a {
    color: color("base-dark");
    font-size: size("ui", "md");
    padding-bottom: units(1.5);

    &.active,
    &:hover {
      border-bottom: units(0.5) solid color("primary");
      color: color("ink");
      padding-bottom: units(1);
      text-decoration: none;
    }
  }
}

.prime-facility-select {
  margin: 0 0 16px 0;
  width: 100%;
  padding: 0;

  @include at-media("desktop") {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    margin-left: units(7);
    padding: 0 0.5rem;
  }

  .usa-form-group {
    @include at-media("desktop") {
      margin-top: 0;
    }
  }
}

// header and navbar customizations
.patient-header {
  justify-content: space-between;
}

.prime-nav {
  justify-content: left;
  padding: 0;
  transition: 0.2s ease-in-out;

  &-link {
    white-space: nowrap;
    height: 100%;
    display: flex !important;
    align-items: center;
  }
}

// header at desktop

@include at-media("desktop") {
  .usa-header--basic {
    .usa-navbar {
      margin-right: units(3);
      width: 13rem;
    }

    .usa-nav {
      padding: 0;

      [data-prefix="fas"] {
        font-size: size("body", "xl");
      }
    }
  }

  .usa-nav__primary {
    > .usa-nav__primary-item {
      > a {
        border-right: 1px solid $theme-color-prime-gray-light;
        font-size: size("heading", "lg");
        font-weight: normal;
        padding: units(3) units(2);

        &.active-nav-item {
          font-weight: font-weight("bold");
        }
      }

      &:first-child > a {
        border-left: 1px solid $theme-color-prime-gray-light;
      }
    }

    .nav__primary-item-icon {
      > a {
        padding: 18.5px units(2.5);
      }
    }
  }

  .usa-logo {
    margin-top: 0;
  }

  .prime-settings-hidden {
    display: none;
  }
}

.usa-logo {
  margin-bottom: 0;

  @include at-media("desktop") {
    margin-bottom: 10px;
  }
}

.active-nav-item {
  background-color: color($theme-color-primary-darker);
  color: white !important;
}

.prime-nav-close-button {
  margin: 0 0 0 auto;

  img {
    width: 1rem;
  }
}

.prime-username-icon-span {
  padding: 1px 8px;
}

.prime-sidenav_inset {
  padding-left: units(2);
  color: black;

  li {
    padding: 13px 7px 7px 0;
    text-align: left;
  }

  /* Anchor in menu is rendered as a styled button. parent Nav forces it to look non-standard */
  button {
    text-decoration: underline;
    color: #069;
  }
}

.span-full-name {
  font-weight: bolder;
}

.prime-organization-name {
  color: color("base");
  font-size: size("body", "3xs");
  margin-left: units(4);
}

.usa-sidenav__sublist,
.mobile-sublist-container {
  hr {
    margin: 1rem -1rem 1rem;
    background-color: color("base-lighter");
    height: 1px;
    border: none;
  }
}

.prime-staff-infobox {
  position: absolute;
  width: 13rem;
  border: 1px solid color("base-lighter");
  border-radius: 0.5rem;
  background-color: white; /* seems like this should be a theme */
  z-index: 10;
  visibility: hidden;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 #00000029;

  li {
    border-top-color: transparent;
    margin-top: units(1);
  }

  li:first-of-type {
    margin-top: 0;
  }

  /* Anchor in menu is rendered as a styled button. parent Nav forces it to look non-standard */
  button {
    color: #069;
    font-weight: font-weight("normal");
    text-decoration: underline;

    @include at-media("desktop") {
      font-size: size("body", "xs");
      padding: 0;
    }
  }

  &-sidemenu {
    li {
      border-color: transparent;
    }
  }
}

.is-prime-staff-infobox-visible {
  visibility: visible;
  z-index: 11;
}

// export

.prime-csv-import-columns {
  overflow-x: scroll;
  width: auto;
  white-space: nowrap;
}

.usa-table--borderless th:first-child {
  padding: 0.5rem 1rem;
}

.usa-table thead tr:hover {
  background-color: transparent;
}

.usa-select,
.usa-range,
.usa-input,
.usa-textarea,
.usa-combo-box__input,
.usa-radio {
  max-width: none;
}

.usa-select-narrow {
  @extend .usa-select;
  max-width: 197px;
}

.usa-combo-box__full-width {
  max-width: none !important;
}

// Patient form
.patient-app--form {
  background-color: color("base-lightest");
}

.prime-formgroup {
  @include card($border-radius: 0.5, $padding: $theme-card-padding-perimeter);

  + .prime-formgroup {
    margin-top: units(1.5);
  }

  &-heading {
    font-size: size("body", "lg");
    font-weight: font-weight("bold");

    + .usa-fieldset {
      margin-top: units(3);
    }
  }
}

.usa-form-group {
  margin-top: units(3);
}

// Modal form
.ReactModal__Content {
  .message--required {
    + .prime-formgroup {
      margin-top: units(2);
    }
  }

  .prime-formgroup {
    border-width: 0;
    margin-top: units(4);
    padding: 0;
  }

  .prime-formgroup__wrapper {
    border: 1px solid color("base-lighter");
    border-radius: units(0.5);
    margin-top: units(2);
    padding: units(2.5);

    > .usa-legend {
      margin-top: 0;
    }
  }

  .prime-formgroup-heading {
    + .usa-fieldset {
      margin-top: units(2);
    }
  }

  // Two-column checkbox layout
  @include at-media("mobile-lg") {
    .symptom-checkboxes {
      .checkboxes {
        column-count: 2;

        .usa-checkbox:first-child {
          .usa-checkbox__label {
            margin-top: 0;
          }
        }
        // The way USWDS was interacting with the two column layout
        // in Chrome was causing checkboxes not to populate for some folks.
        // Using this as a workaround
        .usa-checkbox__label {
          padding-left: 0;
        }

        .usa-checkbox__label::before {
          position: relative;
          float: left;
        }
      }

      > .usa-checkbox {
        .usa-checkbox__label {
          margin-top: 0;
        }
      }

      .usa-checkbox__label {
        line-height: units(3);
      }
    }
  }
}

.usa-step-indicator {
  background-color: transparent;
}

.usa-step-indicator--counters-sm {
  .usa-step-indicator__segment {
    &:last-child {
      // Display the last segment when counters appear
      &::after {
        display: block;
      }
    }
  }

  .usa-step-indicator__segment::before {
    box-shadow:
      inset 0 0 0 units(0.5) color("base-lighter"),
      0 0 0 0.25rem color("base-lightest");
  }

  .usa-step-indicator__segment--complete,
  .usa-step-indicator__segment--current {
    &::before {
      box-shadow: 0 0 0 units(0.5) color("base-lightest");
    }
  }
}

.usa-step-indicator__segment-label {
  // Show labels only at the min-width
  @include at-media($theme-step-indicator-min-width) {
    padding-right: units(2);
  }
}

.usa-step-indicator__segment {
  max-width: none;
}

.sr-time-of-test-footer {
  margin-top: units(3);
}

.sr-time-of-test-buttons-footer {
  text-align: right;
}

[aria-label="Test questionnaire"] {
  margin: 0;
  width: 100%;
  height: 100%;

  @include at-media("tablet") {
    margin: units(5) 0;
    max-height: stretch;
    width: units("tablet");
    height: unset;
    position: relative !important;
    inset: unset !important;
    padding-bottom: 0 !important;
  }
}

@-moz-document url-prefix() {
  [aria-label="Test questionnaire"] {
    .usa-legend {
      margin-top: 0;
    }

    @include at-media("tablet") {
      max-height: calc(100vh - #{units(10)});
    }
  }
}

.patient-app--landing {
  [data-icon="check-circle"] {
    font-size: units(5);

    path {
      fill: color("green-cool-50v");
    }
  }
}

.tag--new {
  background-color: color("green-cool-50v");
}

.usa-form-group--error {
  margin-top: units(3);
}

.tos-content {
  h2 {
    font-size: size("body", "md");
  }

  h3 {
    font-size: size("body", "xs");
  }

  h4 {
    font-size: size("body", "3xs");
  }
}

.patient__header {
  background-color: color("white");
  border: units(2px) solid color("base-lighter");
  border-bottom: units(1px) solid color("base-lighter");
  border-top-left-radius: units(2px);
  border-top-right-radius: units(2px);
  margin-bottom: units(-0.5);
  padding: units(2.5) units(3);
  position: relative;
}

.usa-label {
  margin-top: 0;
}

.modal__footer--sticky {
  z-index: 1;
  position: sticky;
  width: calc(100% + 40px);
  bottom: 0;
  padding: units(2) units(2.5);
  box-shadow: 0 units(-0.5) units(1) 0 rgba(0, 0, 0, 0.1);
  background: color("white");
  transform: translateX(-20px) translateY(20px);

  @include at-media("tablet") {
    transform: translateX(-20px);
  }
}

.aoe-modal__submit-container {
  z-index: 1;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: units(2) units(2.5);
  background: color("white");

  @include at-media("tablet") {
    box-shadow: unset;
    margin: 0 units(-2.5);
    position: unset;
    width: unset;
  }
}

.aoe-modal__qr-container {
  padding-bottom: units(10);

  @include at-media("tablet") {
    padding-bottom: unset;
  }
}

.close-button {
  display: block;
  border: none;
  background: transparent;
  cursor: pointer;
}

.usa-checkbox__label::before,
.usa-radio__label::before {
  box-shadow: 0 0 0 2px #71767a;
  line-height: 1.25rem;
  margin-right: 0.75rem;
  text-indent: 0;
}

.usa-radio__input--tile + .usa-radio__label {
  border: 2px solid #dfe1e2;
}

.sr-element__sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.no-hover-state-when-disabled:hover {
  box-shadow: inset 0 0 0 2px #c9c9c9 !important;
}

abbr.usa-hint.usa-hint--required {
  text-decoration: none;
}

.manage-user-form__site-admin,
.create-user-form__org-admin {
  .usa-form-group {
    .usa-hint {
      font-style: italic;
    }

    .usa-legend {
      font-weight: bold;
    }
  }
}

a[href^='http:']:not([href*='simplereport.gov']),
a[href^='https:']:not([href*='simplereport.gov']) {
  @extend .usa-link--external;

  &::before {
    position: absolute;
    left: -999em;
    right: auto;
    content: "External.";
  }

  &::after {
    margin-top: unset;
    bottom: 0;
    height: 100%;
  }
}

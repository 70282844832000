.sr-queue-item-submit-loader {
  background-color: rgba(255, 255, 255, 0.92);
  display: none;

  &-enter {
    display: block;
    opacity: 0;
  }

  &-enter-active {
    display: block;
    opacity: 1;
    transition: opacity 300ms;
  }

  &-enter-done {
    display: block;
  }

  &-exit {
    display: block;
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  &-exit-done {
    display: none;
  }
}

.test-card-submit-loader {
  top: 0;
  left: 0;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

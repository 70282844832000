.sr-actions-menu {
  color: #005ea2;
  background: inherit;
  padding: 0;
  border: 0;
}

.sr-menu-item {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.thick-bottom-border {
  border-bottom-width: 4px !important;
  width: 300px;
}

.maxw-710 {
  max-width: 44.375rem;
}

.maxw-560 {
  max-width: 35rem;
}

.prime-radio--horizontal__container {
  margin-right: 0.75rem !important;
}

.line-height-225 {
  line-height: 2.25rem !important;
}

@use "../../scss/settings";

.usa-nav-container.prime-header {
  max-width: settings.$site-max-width;

  .prime-nav-link {
    font-size: 1.2rem;
  }

  .prime-facility-select {
    @media (min-width: "desktop") {
      .usa-sidenav__item {
        padding: 0 1.1rem;
      }
    }
    margin: 0;

    select {
      margin: 0.3rem 0;
    }
  }

  .usa-navbar {
    margin-right: 1rem;
  }

  .role-tag {
    border-bottom: 1px #dfe1e2 solid;
    // $theme-color-prime-gray:
    color: #71767a;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .header-link-icon {
    // $theme-color-primary
    color: #005ea2;
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
  }

  .sparkle-icon-mask {
    background-color: #005ea2;
    mask: url("../../img/font-awesome-pro-icons/sparkles.svg") no-repeat center;
  }

  .ghost-user-link {
    display: block;
    padding: 0;
    margin-top: 0.75rem;
    // $theme-color-primary
    color: #005ea2 !important;
  }

  .ghost-user-link:hover {
    background-color: #fff;
  }

  @media (max-width: "desktop") {
    .usa-sidenav__item {
      border-top: none !important;
    }
  }

  .mobile-sublist-container {
    margin-top: 0;
    padding: 0.5rem 1rem;
  }

  .mobile-sublist-container .usa-sidenav__item {
    margin-top: 0.5rem;
    border-top: none;
  }

  .mobile-menu-action {
    margin-left: 0.5rem;
  }

  .nav__primary-item-icon:last-child {
    border-bottom: 1px solid #dfe1e2;
  }

  .mobile-secondary-nav-container {
    margin-top: 0;
    font-size: 1.2rem;
  }

  .mobile-secondary-nav-container a,
  .mobile-main-nav-container a {
    padding: 0.75rem 1rem;
  }

  .mobile-facility-select {
    margin-top: 0;
  }

  .mobile-facility-select select {
    width: 100%;
  }

  .navlink__support {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;

    button {
      color: #005ea2;
      text-decoration: none;
      padding: 0 !important;
    }

    a {
      color: #005ea2 !important;
      font-weight: 500;
      padding: 0 !important;
    }

    a:hover,
    button:hover {
      color: #005ea2;
      text-decoration: underline;
      font-weight: 700;
      background-color: #fff;
    }

    a:visited {
      color: #005ea2;
    }
  }

  .facility-select-mobile-container {
    padding-bottom: 2rem;
  }
}

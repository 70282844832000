.address__select {
  @media (min-width: 620px) {
    > div {
      display: flex;
      gap: 1rem;
    }

    .usa-radio {
      flex: 1 1;
    }
  }

  .radio--disabled {
    display: flex;
  }

  .usa-radio__label {
    margin-bottom: 0 !important;
  }

  .usa-radio__input:disabled {
    + .usa-radio__label {
      border-color: #f0f0f0; // base-lightest
      flex: 1 1;
      justify-content: center;

      &::before {
        display: none;
      }
    }
  }
}

.address__no-suggestion {
  align-self: center;
}

.sign-up-goals-card {
  h3 {
    font-family: Public Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
  }

  p.subheader {
    font-family: Public Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 162%;
  }

  .usa-radio__label {
    font-weight: 600;
  }

  .usa-checkbox__label-description {
    font-size: 13px;
    font-weight: 400;
  }
}

.basic-info-header {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #1b1b1b;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.userinfo-subheader {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #1b1b1b;
  margin-bottom: 5px;
}

.userinfo-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 162%;
  color: #1b1b1b;
  margin-top: 0;
}

// -------------------------------------
// Typography

.maxw-prose {
  max-width: 68ex;
}

// -------------------------------------
// Color

// Text color

.text-success {
  color: color("success");
}

.text-error {
  color: color("error");
}

// Background color

.bg-success {
  background-color: color("success");
}

.bg-error {
  background-color: color("error");
}

.width-fit-content {
  width: fit-content;
}

.header-size-fix {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18.75px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 13.28px;
  }

  h5 {
    font-size: 10.72px;
  }
}

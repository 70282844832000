.sr-showing-patients-on-page {
  color: #555;
  font-size: 0.69rem;
}

.sr-patient-list {
  .sr-patient-row th,
  .sr-patient-row td {
    /* override USWDS force-white */
    background: inherit;
  }

  .sr-patient-row-header {
    max-width: 18.75rem;
  }

  .sr-patient-row--removed {
    text-decoration: line-through;
  }

  .rc-menu-button {
    background: inherit;
    padding: 0;
    border: 0;
  }
}

.patient-form-deletion-field {
  width: calc(100% + 2.5rem);
}

@use "../settings" as *;
@use "@uswds/uswds/packages/usa-link";

.sr-link__primary {
  @extend .usa-link;

  @include u-text('semibold');
  @include u-text('no-underline');

  &:hover {
    @include u-text('underline');
  }
}

.prime-edit-patient {
  .sr-add-another-button {
    column-gap: 0;
  }

  .sr-patient-sub-header {
    font-weight: bold;
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
}

@media print {
  body {
    // ensures extra content is not printed in results page
    &.ReactModal__Body--open {
      #root {
        display: none !important;
      }
    }

    a[href^='http:']:not([href*='simplereport.gov']),
    a[href^='https:']:not([href*='simplereport.gov']) {
      &::after {
        bottom: 0;
        height: 1rem;
      }
    }

    .sr-show-on-print {
      display: block !important;
      margin: 0 0 0 auto;
    }

    // ensures overflow content prints on next page
    .sr-test-results-modal-overlay {
      display: block;
      overflow: visible;
      position: static;
    }

    // ensures extra content is not printed
    .dont-print,
    .usa-header,
    .usa-banner {
      display: none !important;
    }

    .sr-test-results-modal-content {
      margin: 0 auto;
      max-height: none;
      padding: 0;
      transform: none;
      width: 8.5in;
    }
  }
}

.sr-result-print-buttons {
  display: flex;
  justify-content: flex-end;

  & button {
    margin: 0 0 0 1.5rem;
  }
}

.sr-removed-result::before {
  position: absolute;
  font-size: 4rem;
  opacity: 50%;
  color: #666;
  transform: rotate(-30deg) translate(-8rem, 12rem);
  outline: 0.2rem solid #888;
  -webkit-text-stroke: 1px #fff;
  content: "MARKED AS ERROR";
  background: transparent;
}

.sr-test-results-modal-content {
  font-size: 80% !important;
  background: #fff;
  margin-top: 2%;
  inset: 3em auto auto auto;
  overflow: auto;
  max-height: 90vh;
  width: 7.6in;
  padding: 0.4in;
}

.sr-test-results-modal-overlay {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.sr-test-result-report {
  header {
    & > .sr-print-logo {
      float: right;
      width: 200px;
    }

    h1 {
      padding: 0;
      margin: 0;
    }
  }

  main {
    display: flex;
    flex-wrap: wrap;
  }

  footer {
    p {
      margin-bottom: 0;
    }
  }

  a[href^='http:']:not([href*='simplereport.gov']),
  a[href^='https:']:not([href*='simplereport.gov']) {
    &::after {
      bottom: 0;
      height: 1rem;
    }
  }
}

.sr-result-section {
  width: 100%;
  border: 1px solid #767676;
  margin: 0.25rem 0;

  h2 {
    font-size: 100%;
    font-weight: normal;
    color: #fcfcfc;
    background: #6c6c6c;
    margin: 0;
    padding: 0.5rem;

    /* allow dark background on header */
    -webkit-print-color-adjust: exact;
  }

  h3 {
    margin: 0.75rem 1rem;
  }

  ul.sr-multi-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 14ex;
  }

  p {
    margin: 0.75rem 1rem;
  }

  .sr-guidance-heading {
    margin-bottom: 0.625rem;
  }

  .sr-margin-bottom-28px {
    margin-bottom: 1.75rem;
  }
}

.sr-result-patient-details,
.sr-result-facility-details {
  width: 49%;
}

.sr-result-facility-details {
  margin-left: 2%;
}

.sr-result-test-details {
  .sr-details-list {
    li {
      b {
        width: unset !important;
        min-width: 15rem;
        max-width: 15rem;
      }
    }
  }
}

.sr-details-list {
  list-style-type: none;
  margin-left: 0.5rem;
  padding: 0;

  li {
    margin: 0.5rem;
    display: flex;
    flex-direction: row;

    b {
      width: 8rem;
      flex-shrink: 0;
    }

    .sr-result-facility-details-address span {
      display: block;
    }

    div {
      margin-left: 0.5rem;
    }
  }

  div:empty::after {
    content: ".";
    visibility: hidden;
  }
}

#experian-questions-header {
  border-bottom: none;
  padding: 1.5rem 0 0 0;

  h4 {
    margin-bottom: 0;
  }

  .timer-button {
    margin-left: auto;
  }
}

.experian-countdown-alert {
  line-height: 1.4;
  font-size: 1rem;
}

.questions-form-name {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

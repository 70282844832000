.settings-tab {
  .usa-card__header {
    padding-bottom: 2rem;
    min-height: 6rem;

    h1 {
      font-size: 1.5em;
      margin-top: 8px !important;
      margin-bottom: 0 !important;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      font-family: Public Sans, sans-serif;
    }

    h2 {
      margin-top: 8px;
    }

    button.usa-button {
      margin-right: 1rem;
    }

    a.usa-button {
      height: fit-content;
    }
  }
}

.grid-container.pending-orgs-wide-container {
  max-width: none;
}

.sr-pending-organizations-list {
  .sr-org-row th,
  .sr-org-row td {
    /* override USWDS force-white */
    background: inherit;

    .usa-form-group {
      margin-top: -2rem;
    }
  }

  .sr-org-row:hover {
    background: #f0f0f0;

    .sr-pending-org-delete-button {
      background: #f0f0f0;
    }
  }
}

.sr-pending-org-delete-button {
  border: none;
  background-color: #fff;
}

.sr-pending-org-delete-button > svg > path {
  fill: #005ea2;
}

.sr-pending-org-delete-button:hover > svg > path {
  fill: #1a4480;
}

.sr-pending-org-delete-button:hover {
  cursor: pointer;
}

.word-break-break-word {
  word-break: break-word;
}

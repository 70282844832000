@use "../../scss/settings" as settings;
@use "@uswds/uswds/packages/uswds-core" as uswds;

.timer-button {
  border-radius: 3px;
  min-width: 80px;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.timer-reset {
  color: #005ea2;
  background-color: white;
  border: 1px solid settings.$theme-color-prime-blue;
}

.timer-running {
  background-color: #162e51;
  color: white;
}

.timer-ready {
  color: white;
  background-color: uswds.color(settings.$theme-color-success-dark);
  border: none;
}

.timer-overtime {
  color: settings.$color-white-transparent-80;
  font-style: italic;
  min-width: 6rem;
}

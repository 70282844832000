.sr-registration-link-description {
  color: #71767a;
  max-width: 22em;
}

.sr-organization-link-container {
  .sr-organization-link-input {
    margin-top: 0;
    height: auto;
  }

  .sr-organization-link-copy-button {
    border-radius: 0 0 4px 4px;
    column-gap: 0;
    width: 100%;
  }

  @media (min-width: 480px) {
    display: flex;

    .sr-organization-link-copy-button {
      flex-shrink: 0;
      border-radius: 0 4px 4px 0;
      width: auto;
    }
  }
}

.prime-container.manage-users-card {
  * {
    font-family: Public Sans, sans-serif;
  }

  .users-sidenav {
    border-right: 1px solid #dfe1e2;
    width: 25%;

    .users-sidenav-header {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #1b1b1b;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }

  .users-sidenav-item:hover {
    cursor: pointer;
  }

  .user-header {
    margin-bottom: 1em;
  }

  .user-content {
    padding: 0 0.5em;
  }

  .reset-button {
    display: block;
    margin: 1em auto;
  }

  .no-results-found {
    width: 75%;
  }

  .clear-filter-button {
    width: auto !important;
  }

  .usa-pagination ol {
    padding-left: 0 !important;
  }

  .usa-sidenav__item.users-sidenav-item button {
    width: 100%;
  }

  .usa-current {
    .sidenav-user-name {
      font-weight: bold;
      color: #005ea2;
    }
  }

  .sidenav-user-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 162%;
    padding-bottom: 1px;
  }

  .sidenav-user-status {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.025em;
    color: #1b1b1b;
    padding-left: 6px;
  }

  .suspended-icon {
    width: 0.7em !important;
  }

  .top-user-status {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 162%;
    color: #1b1b1b;
    padding-left: 10px;
  }

  .facility-list {
    td {
      border: 1px solid #dfe1e2;
    }
  }

  .user-facilities td:nth-child(2) {
    text-align: right;
  }

  .remove-tag {
    cursor: pointer;
  }

  .modal-bottom-border {
    border-bottom: 1px solid #f4f4f4;
  }

  .remove-tag--disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.5;
  }

  .usa-card__header,
  .usa-card__body {
    padding-left: 1.1rem;
    padding-right: 1.5rem;
  }

  .usa-card__header {
    padding-bottom: 2rem;

    h1 {
      font-size: 1.5em;
      margin-top: 8px !important;
      margin-bottom: 0 !important;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
    }

    h2 {
      margin-top: 8px;
    }
  }

  .user-detail-column {
    nav {
      border-bottom: 1px solid #dcdee0;
      padding-bottom: 4px;
      margin-right: -18px;
      margin-left: -24px;
      padding-left: 20px;

      .usa-nav__secondary-item {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        color: #1b1b1b;

        button {
          padding-bottom: 2px;
        }

        &.usa-current button {
          font-weight: 600;
          border-bottom: 0.25rem solid #005ea2;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .user-controls-header {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #1b1b1b;
      margin-bottom: 2rem;
      margin-block-start: 1.33em;
    }

    .usercontrols-text {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #3d4551;
    }

    .disabled-dark * {
      color: #757575 !important;
    }

    .usa-checkbox__label-description.text-base-dark {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.025em;
      color: #565c65;
      margin-top: 0;
    }

    .testing-facility-access-subheader {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #1b1b1b;
      margin-block-start: 1em;
    }

    .testing-facility-access-subtext {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: #3d4551;
      margin-top: 8px;
    }
  }
}
